import { register } from "register-service-worker";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "La aplicación está siendo servida desde el caché por el Service Worker."
    );
  },
  registered() {
    console.log("Service Worker registrado.");
  },
  cached() {
    console.log("Contenido en caché para uso sin conexión.");
    if(document.getElementById("overlay-new-version")) {
      document.getElementById("overlay-new-version").remove();
    }
  },
  updatefound() {
    console.log("Nueva versión encontrada. Descargando...");
    // Mostrar overlay de carga para bloquear la interacción
    document.body.classList.add("updating");
    const overlay = document.createElement("div");
    overlay.id = "overlay-new-version";
    overlay.className = "overlay overlay-new-version";
    overlay.innerHTML = "<p>Actualizando...</p>";
    document.body.appendChild(overlay);
  },
  updated(registration) {
    document.getElementById("overlay-new-version").remove();
    console.log("Nueva versión lista. Recarga la página para actualizar.");
    if (
      confirm(
        "Se descargó nueva versión de la aplicación. ¿Desea actualizarla ahora?"
      )
    ) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      location.reload();
    }
  },
  offline() {
    confirm(
      "La aplicación está fuera de línea. Por favor, revisa tu conexión a internet."
    );
    console.log(
      "La aplicación está fuera de línea. Por favor, revisa tu conexión a internet."
    );
  },
  error(error) {
    console.error("Error durante la instalación del Service Worker:", error);
  },
});