import { helper } from "../helpers/helper";
import { useStore } from "../state/useStore";
import { abortAxios } from "./network";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Home",
      //authRequired: true,
    },
    // component: () => import("../views/dashboard-dinamico/dashboard-dinamico"),
    component: () => import("../views/dashboard-dinamico/dashboard-dinamico"),
    beforeEnter: () => {
      abortAxios();
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        useStore().resetStore();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },

  //RUTAS CUSTOMIZADAS

  /* Users: */
  {
    path: "/users/list-users",
    name: "Ver Usuarios",
    meta: {
      title: "Ver Usuarios",
      authRequired: true,
    },
    component: () => import("../views/users/list-users"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeUsers-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/users/create-user",
    name: "Crear Usuario",
    meta: {
      title: "Crear Usuario",
      authRequired: true,
    },
    component: () => import("../views/users/create-user"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeUsers-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/users/edit-user/:id",
    name: "Editar Usuario",
    meta: {
      title: "Editar Usuario",
      authRequired: true,
    },
    component: () => import("../views/users/edit-users"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeUsers-edit"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/users/edit-profile/:id",
    name: "Editar Perfil",
    meta: {
      title: "Editar Perfil",
      authRequired: true,
    },
    component: () => import("../views/users/edit-profile"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("front-myself-edit");
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/users/profile-user",
    name: "Perfil Usuario",
    meta: {
      title: "Perfil Usuario",
      authRequired: true,
    },
    component: () => import("../views/users/see-profile"),
    beforeEnter: () => {
      abortAxios();
    },
  },
  /* Telegestión */
  {
    path: "/g-punto-a-punto",
    name: "Telegestión",
    meta: {
      title: "Telegestión",
    },
    component: () => import("../views/gestion/g-punto-a-punto.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGpap-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  /* TownHall: */
  {
    path: "/townhalls/list-town-halls",
    name: "Ver Municipios",
    meta: {
      title: "Ver Municipios",
      authRequired: true,
    },
    component: () => import("../views/townhalls/vista-map-list-townhalls"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-townHall-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/townhalls/create-townhall",
    name: "Crear Municipio",
    meta: {
      title: "Crear Municipio",
      authRequired: true,
    },
    component: () => import("../views/townhalls/vista-create-townhall"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-townHall-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/townhalls/town-hall/:id",
    name: "Vista Municipio",
    meta: {
      title: "Vista Municipio",
      authRequired: true,
    },
    component: () => import("../views/townhalls/view-and-edit-townhall"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-townHall-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/command-centers/list-command-centers",
    name: "Ver Centros de mando",
    meta: {
      title: "Ver Centros de mando",
      authRequired: true,
    },
    component: () =>
      import("../views/command-centers/vista-map-list-command-centers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-commandCenter-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/command-centers/create-command-center",
    name: "Crear Centros de mando",
    meta: {
      title: "Crear Centros de mando",
      authRequired: true,
    },
    component: () =>
      import("../views/command-centers/vista-create-command-center"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-commandCenter-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/command-centers/command-center/:id",
    name: "Vista Centros de mando",
    meta: {
      title: "Vista Centros de mando",
      authRequired: true,
    },
    component: () =>
      import("../views/command-centers/view-and-edit-command-center"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-commandCenter-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/issue/new-issue",
    name: "Crear Incidencia",
    meta: {
      title: "Crear Incidencia",
      authRequired: true,
    },
    component: () => import("../views/gestion/create-issue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-issue-create");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/streetlights/list-streetlights",
    name: "Lista Luminarias",
    meta: {
      title: "Lista Luminarias",
      authRequired: true,
    },
    component: () => import("../views/streetlight/vista-map-list-streetlights"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-streetlight-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/streetlights/create-streetlight",
    name: "Añadir Luminaria",
    meta: {
      title: "Añadir Luminaria",
      authRequired: true,
    },
    component: () => import("../views/streetlight/vista-create-streetlight"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-streetlight-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/streetlights/streetlight/:id",
    name: "Editar Luminaria",
    meta: {
      title: "Editar Luminaria",
      authRequired: true,
    },
    component: () => import("../views/streetlight/view-and-edit-streetlight"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-streetlight-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/buildings/create-buildings",
    name: "Añadir Edificio",
    meta: {
      title: "Añadir Edificio",
      authRequired: true,
    },
    component: () => import("../views/buildings/vista-create-buildings"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-building-create"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/buildings/list-buildings",
    name: "Lista Edificios",
    meta: {
      title: "Lista Edificios",
      authRequired: true,
    },
    component: () => import("../views/buildings/vista-map-list-buildings"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-building-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/buildings/building/:id",
    name: "Editar Edificio",
    meta: {
      title: "Editar Edificio",
      authRequired: true,
    },
    component: () => import("../views/buildings/view-and-edit-buildings"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-building-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/assets/create-assets",
    name: "Añadir Activos",
    meta: {
      title: "Añadir Activos",
      authRequired: true,
    },
    component: () => import("../views/assets/vista-create-assets"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-create");
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/assets/list-assets",
    name: "Lista de Activos",
    meta: {
      title: "Lista de Activos",
      authRequired: true,
    },
    component: () => import("../views/assets/vista-map-list-assets"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-viewAny");
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/assets/asset/:id",
    name: "Editar Activo",
    meta: {
      title: "Editar Activo",
      authRequired: true,
    },
    component: () => import("../views/assets/view-and-edit-assets"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-update");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/logger/last-logger-analyzers",
    name: "Last logger analyzers",
    meta: {
      title: "Lista last logger analyzers",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-analyzers/list-last-logger-analyzers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/last-logger-analyzers/last-logger-analyzer/:id",
    name: "Ver last logger analyzer",
    meta: {
      title: "Ver last logger analyzer",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-analyzers/view-last-logger-analyzers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/last-logger-analyzers/last-logger-analyzer/view-data/:id",
    name: "Ver last logger analyzer",
    meta: {
      title: "Ver last logger analyzer",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-analyzers/view-last-logger-analyzers-json"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/last-logger-modems",
    name: "Last logger modems",
    meta: {
      title: "Lista last logger modems",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-modems/list-last-logger-modems"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/last-logger-modem/:id",
    name: "Ver last logger modems",
    meta: {
      title: "Ver last logger modems",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-modems/view-last-logger-modems"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/last-logger-modems/last-logger-modem/view-data/:id",
    name: "Ver last logger modem",
    meta: {
      title: "Ver last logger modem",
      authRequired: true,
    },
    component: () =>
      import("../views/last-logger-modems/view-last-logger-modems-json"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/edicion-multiple/:modelo",
    name: "Edición múltiple",
    meta: {
      title: "Edición múltiple",
      authRequired: true,
    },
    component: () => import("../views/edicion-multiple/edicion-multiple"),
  },
  {
    path: "/db-tables/list-db-tables",
    name: "Tablas BBDD",
    meta: {
      title: "Ver tablas BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/list-dbtables"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeTablesBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-tables/edit-db-table/:id",
    name: "Ver tablas BBDD",
    meta: {
      title: "Ver tablas BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/view-and-edit-dbtables"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeTablesBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-fields/list-db-fields",
    name: "campos BBDD",
    meta: {
      title: "Ver campos BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/list-dbfields"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeFieldsBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-fields/edit-db-field/:id",
    name: "Ver campos BBDD",
    meta: {
      title: "Ver campos BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/view-and-edit-dbfields"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeFieldsBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-groups/list-db-groups",
    name: "grupos BBDD",
    meta: {
      title: "Ver grupos BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/list-dbgroups"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGroupsBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-groups/edit-db-group/:id",
    name: "Ver grupos BBDD",
    meta: {
      title: "Ver grupos BBDD",
      authRequired: true,
    },
    component: () => import("../views/db/view-and-edit-dbgroups"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGroupsBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/db-groups/create-dbgroup",
    name: "Añadir Grupo",
    meta: {
      title: "Añadir Grupo",
      authRequired: true,
    },
    component: () => import("../views/db/create-dbgroup"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGroupsBBDD-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/issue/:id",
    name: "Incidencia",
    meta: {
      title: "Incidencia",
      authRequired: true,
    },
    component: () => import("../views/gestion/view-and-edit-incidencia"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-issue-view");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/edit-issue/:id",
    name: "Editar incidencia",
    meta: {
      title: "Editar incidencia",
      authRequired: true,
    },
    component: () => import("../views/gestion/edit-incidencia"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-issue-update");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-tasks/list-scheduled-tasks",
    name: "Tareas programadas",
    meta: {
      title: "Tareas programadas",
      authRequired: true,
    },
    component: () => import("../views/scheduled-tasks/list-scheduled-tasks"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-taskScheduled-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-tasks/create-scheduled-task/",
    name: "Crea Tarea Programada",
    meta: {
      title: "Crea Tarea Programada",
      authRequired: true,
    },
    component: () => import("../views/scheduled-tasks/create-scheduled-task"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-taskScheduled-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-tasks/scheduled-task/:id",
    name: "Editar Tarea Programada",
    meta: {
      title: "Editar Tarea Programada",
      authRequired: true,
    },
    component: () =>
      import("../views/scheduled-tasks/view-and-edit-scheduled-task"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-taskScheduled-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/gmao",
    name: "Gestión de Mantenimiento",
    meta: {
      title: "Gestión de Mantenimiento",
      authRequired: true,
    },
    component: () => import("../views/gestion/vista-gmao"),
    beforeEnter: (to, from, next) => {
      abortAxios();
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/gmao/statistics",
    name: "Estadisticas GMAO",
    meta: {
      title: "statistics",
      authRequired: true,
    },
    component: () => import("../views/gestion/view-gmao-stats.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/gmao/issues-list",
    name: "Listado de incidencias",
    meta: {
      title: "Listado de incidencias",
      authRequired: true,
    },
    component: () => import("../views/gestion/issuesList"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issues/list-scheduled-issues",
    name: "Mantenimientos Preventivos",
    meta: {
      title: "Mantenimientos Preventivos",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/list-scheduled-issues"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssue-viewAny"
      );
      // let userHasPermission = helper.middlewarePermission('front-routeScheduledIssue-create')

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issues/create-scheduled-issue/",
    name: "Crear Mantenimiento Preventivo",
    meta: {
      title: "Crear Mantenimiento Preventivo",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/create-scheduled-issue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssue-create"
      );
      // let userHasPermission = helper.middlewarePermission('front-routeScheduledIssue-create')

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issues/scheduled-issue/:id",
    name: "Editar Mantenimiento Preventivo",
    meta: {
      title: "Editar Mantenimiento Preventivo",
      authRequired: true,
    },
    component: () =>
      import("../views/scheduled-issues/view-and-edit-scheduled-issue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssue-edit"
      );
      // let userHasPermission = helper.middlewarePermission('front-routeScheduledIssue-create')

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issue-templates/list-scheduled-issue-templates",
    name: "Plantillas",
    meta: {
      title: "Plantillas",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/list-templates"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssueTemplate-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issue-templates/create-template/",
    name: "Crear Plantilla",
    meta: {
      title: "Crear Plantilla",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/create-template"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssueTemplate-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issue-templates/template/:id",
    name: "Editar Plantilla",
    meta: {
      title: "Editar Plantilla",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/view-and-edit-template"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssueTemplate-edit"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/events-calendar/view-calendar",
    name: "Ver Calendario de eventos",
    meta: {
      title: "Ver Calendario de eventos",
      authRequired: true,
    },
    component: () => import("../views/events-calendar/view-calendar"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeCalendar-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/event-types/list-event-types",
    name: "Listado de tipos de eventos",
    meta: {
      title: "Listado de tipos de eventos",
      authRequired: true,
    },
    component: () => import("../views/events-calendar/list-event-types"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-eventType-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/event-types/create-event-type",
    name: "Crear Tipo de Evento",
    meta: {
      title: "Crear Tipo de Evento",
      authRequired: true,
    },
    component: () => import("../views/events-calendar/vista-create-event-type"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-eventType-create"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/event-types/event-type/:id",
    name: "Editar Tipo de Evento",
    meta: {
      title: "Editar Tipo de Evento",
      authRequired: true,
    },
    component: () =>
      import("../views/events-calendar/view-and-edit-event-types"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-eventType-edit"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/base-abilities/list-base-abilities",
    name: "Listado de habilidades",
    meta: {
      title: "Listado de habilidades",
      authRequired: true,
    },
    component: () => import("../views/abilities/list-base-abilities"),
    beforeEnter: (to, from, next) => {
      let userIsSuperAdmin = JSON.parse(useStore().user).is_superadmin;
      if (userIsSuperAdmin == 1) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/base-abilities/base-ability/:id",
    name: "Editar habilidad",
    meta: {
      title: "Editar habilidad",
      authRequired: true,
    },
    component: () => import("../views/abilities/view-and-edit-base-abilities"),
    beforeEnter: (to, from, next) => {
      let userIsSuperAdmin = JSON.parse(useStore().user).is_superadmin;
      if (userIsSuperAdmin == 1) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/base-abilities/create-base-ability/",
    name: "Crea Habilidad",
    meta: {
      title: "Crea Habilidad",
      authRequired: true,
    },
    component: () => import("../views/abilities/vista-create-base-ability"),
    beforeEnter: (to, from, next) => {
      let userIsSuperAdmin = JSON.parse(useStore().user).is_superadmin;
      if (userIsSuperAdmin == 1) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/preventive-issues",
    name: "Tareas preventivas",
    meta: {
      title: "Tareas preventivas",
      authRequired: true,
    },

    // component: () => import("../views/vistas-comodin/vista-comodin-1"),
    component: () => import("../views/preventive-issues/preventive-issues"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/correctives-issues",
    name: "Tareas Correctivas",
    meta: {
      title: "Tareas Correctivas",
      authRequired: true,
    },
    component: () => import("../views/correctives-issues/correctives-issues"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/streetlights/edit-multiple",
    name: "Edición multiple de luminarias",
    meta: {
      title: "Edición multiple de luminarias",
      authRequired: true,
    },
    component: () =>
      import("../views/streetlight/vista-edit-multiple-streetlights"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-streetlight-edit"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/incidents/create-incidents",
    name: "Reportar Incidencia",
    meta: {
      title: "Reportar Incidencia",
      authRequired: true,
    },
    component: () => import("../views/incidents/create-incidents"),
    beforeEnter: (to, from, next) => {
      abortAxios();
      next();
    },
  },
  {
    path: "/incidents/view-incidents",
    name: "Ver Incidencias",
    meta: {
      title: "Ver Incidencias",
      authRequired: true,
    },
    component: () => import("../views/incidents/view-incidents"),
    beforeEnter: (to, from, next) => {
      abortAxios();
      next();
    },
  },
  {
    path: "/incidents/list-projects",
    name: "Proyectos",
    meta: {
      title: "Projectos",
      authRequired: true,
    },
    component: () => import("../views/incidents/list-projects"),
    beforeEnter: (to, from, next) => {
      abortAxios();
      next();
    },
  },
  {
    path: "/incidents",
    name: "Incidencias",
    meta: {
      title: "Incidencias",
      authRequired: true,
    },
    component: () => import("../views/incidents/incidents"),
    beforeEnter: (to, from, next) => {
      abortAxios();
      next();
    },
  },
  {
    path: "/subassets/create-subasset",
    name: "Añadir Pieza",
    meta: {
      title: "Añadir Pieza",
      authRequired: true,
    },
    component: () => import("../views/subasset/vista-create-subasset"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-create");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/subassets/subasset/:id",
    name: "Editar Pieza",
    meta: {
      title: "Editar Pieza",
      authRequired: true,
    },
    component: () => import("../views/subasset/view-and-edit-subasset"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-viewAny");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/subassets/list-subassets",
    name: "Lista Piezas",
    meta: {
      title: "Lista Piezas",
      authRequired: true,
    },
    component: () => import("../views/subasset/vista-map-list-subassets"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission("core-asset-viewAny");

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404 Página no encontrada",
    meta: {
      title: "404 Página no encontrada",
      authRequired: true,
    },
    component: () => import("../views/page-not-found"),
  },
  {
    path: "/chart-generator",
    name: "Generador de gráficos avanzado",
    meta: {
      title: "Generador de gráficos avanzado",
      authRequired: true,
    },
    component: () => import("../views/chart-generator/chart-generator"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/chart-generator-simple",
    name: "Generador de gráficos simple",
    meta: {
      title: "Generador de gráficos simple",
      authRequired: true,
    },
    component: () => import("../views/chart-generator/chart-generator-simple"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGmao-view"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/logger-analyzers/list-logger-analyzers",
    name: "Ver listado logger-analyzer",
    meta: {
      title: "Ver listado logger-analyzer",
      authRequired: true,
    },
    component: () => import("../views/loggers/view-logger-analyzers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/logger-modems",
    name: "Ver listado logger-modem",
    meta: {
      title: "Ver listado logger-modem",
      authRequired: true,
    },
    component: () => import("../views/loggers/list-logger-modems"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/logger-modems/view-data/",
    name: "Ver logger modem",
    meta: {
      title: "Ver logger modem",
      authRequired: true,
    },
    component: () => import("../views/loggers/view-logger-modems-json"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/logger-analyzers",
    name: "Ver listado logger-analyzer",
    meta: {
      title: "Ver listado logger-analyzer",
      authRequired: true,
    },
    component: () => import("../views/loggers/list-logger-analyzers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/logger-analyzers/view-data/",
    name: "Ver logger analyzer",
    meta: {
      title: "Ver logger analyzer",
      authRequired: true,
    },
    component: () => import("../views/loggers/view-logger-analyzers-json"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerAnalyzer-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loggers/modem-status",
    name: "Ver estado del modem",
    meta: {
      title: "Ver estado del modem",
      authRequired: true,
    },
    component: () => import("../views/loggers/modem-status"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-lastLoggerModem-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/issue-forms/list-issue-forms",
    name: "Ver formularios de incidencias",
    meta: {
      title: "Ver formularios de incidencias",
      authRequired: true,
    },
    component: () => import("../views/issue-forms/list-issue-forms"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-issueForm-viewAny"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/issue-forms/issue-form/:id",
    name: "Editar formulario de incidencias",
    meta: {
      title: "Editar formulario de incidencias",
      authRequired: true,
    },
    component: () => import("../views/issue-forms/edit-issue-form"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-issueForm-update"
      );
      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/trigger-exceptions/list-trigger-exceptions",
    name: "Lista Excepciones",
    meta: {
      title: "Lista Excepciones",
      authRequired: true,
    },
    component: () =>
      import("../views/trigger-exceptions/vista-list-trigger-exceptions"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/trigger-exceptions/trigger-exception/:id",
    name: "Editar Excepción",
    meta: {
      title: "Editar Excepción",
      authRequired: true,
    },
    component: () =>
      import("../views/trigger-exceptions/view-and-edit-trigger-exceptions"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/triggers/list-triggers",
    name: "Lista Triggers",
    meta: {
      title: "Lista Triggers",
      authRequired: true,
    },
    component: () =>
      import("../views/triggers/vista-list-triggers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/triggers/trigger/:id",
    name: "Editar Triggers",
    meta: {
      title: "Editar Triggers",
      authRequired: true,
    },
    component: () =>
      import("../views/triggers/view-and-edit-triggers"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/trigger-exceptions/create-trigger-exceptions",
    name: "Crear Excepción",
    meta: {
      title: "Crear Excepción",
      authRequired: true,
    },
    component: () =>
      import("../views/trigger-exceptions/create-trigger-exceptions.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/triggers/create-trigger",
    name: "Crear Triggers",
    meta: {
      title: "Crear Triggers",
      authRequired: true,
    },
    component: () =>
      import("../views/triggers/create-triggers.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = true;

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/scheduled-issues/gantt-diagram",
    name: "Diagrama de Gantt",
    meta: {
      title: "Diagrama de Gantt",
      authRequired: true,
    },
    component: () => import("../views/scheduled-issues/view-gantt-diagram"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "core-scheduledIssue-viewAny"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/app-buttons/list-app-buttons",
    name: "App Buttons",
    meta: {
      title: "App Buttons",
      authRequired: true,
    },
    component: () => import("../views/appButtons/list-app-buttons"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-listAppButtons-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/smartcity",
    name: "SmartCity",
    meta: {
      title: "SmartCity",
      authRequired: true,
    },
    component: () => import("../views/smartcity/index.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeSmartCity-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/energy-management",
    name: "energy-management",
    meta: {
      title: "energy-management",
      authRequired: true,
    },
    component: () => import("../views/energy-management/index.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGpap-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/energy-management/period-comparison",
    name: "energy-management/period-comparison",
    meta: {
      title: "energy-management",
      authRequired: true,
    },
    component: () => import("../views/energy-management/energyManagementPeriodComparison.vue"),
    beforeEnter: (to, from, next) => {
      let userHasPermission = helper.middlewarePermission(
        "front-routeGpap-view"
      );

      if (userHasPermission) {
        abortAxios();
        next();
      } else {
        next("/");
      }
    },
  },
];
